:root {
  --background-color-light: white;
  --text-color-light: black;
  --background-color-dark: #131313;
  --text-color-dark: #e0e0e0;
  --link-color-light: #0071F9;
  --link-color-dark: #009AFF;
  -webkit-touch-callout: none;
  touch-action: none;
  user-select: none;
  -webkit-user-select: none;
  -webkit-user-callout: none;
  -webkit-highlight: none;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  -webkit-touch-callout: none;
  touch-action: none;
  user-select: none;
  -webkit-user-select: none;
}

body {
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.board-svg {
  display: block;
  margin: auto;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: none !important;
  animation: none !important;
  transform: translate3d(0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -webkit-touch-callout: none;
  width: 100vw;
  touch-action: none;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.board-svg.grid-visible {
  top: 52%;
  max-height: min(83vh, calc(100dvh - 69pt));
}

.board-svg.grid-hidden {
  top: 50.9%;
  max-height: min(90vh, calc(100dvh - 69pt));
}

@media (orientation: portrait) {
  .board-svg.grid-hidden {
    top: 51%;
  }

  .board-svg.grid-visible {
    max-height: min(83vh, calc(100dvh - 50pt));
    top: 52.5%;
  }

  @media (max-height: 547px) {
    .board-svg.grid-hidden {
      top: 52%;
    }

    .board-svg.grid-visible {
      top: 54.5%;
    }
  }
}

.item {
  -webkit-tap-highlight-color: transparent;
  pointer-events: none;
  touch-action: none;
  outline: none;
  transform: translate3d(0, 0, 0);
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: var(--background-color-dark);
    color: var(--text-color-dark);
  }

  a {
    color: var(--link-color-dark);
  }
}

@media (prefers-color-scheme: light) {
  body {
    background-color: var(--background-color-light);
    color: var(--text-color-light);
  }

  a {
    color: var(--link-color-light);
  }
}

a,
img {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: none;
}

.top-buttons-container {
  position: absolute;
  top: 9pt;
  right: 9pt;
  display: flex;
  align-items: center;
}

.small-top-control-buttons {
  display: flex;
  margin-right: 10px;
  border-radius: 14px;
  background-color: #f9f9f9de;


  @media (max-width: 320px) {
    margin-right: 6px;
  }

  @media (prefers-color-scheme: dark) {
    background-color: #252525d5;
  }
}

.music-button,
.sound-button,
.info-button {
  border: none;
  cursor: pointer;
  font-size: 13px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #767787c9;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -webkit-touch-callout: none;
  touch-action: none;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: #767787ef;
    }
  }

  @media (prefers-color-scheme: dark) {
    color: #767787a9;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: #767787f0;
      }
    }
  }
}

.sound-button {
  margin-right: 11px;

  @media (max-width: 380px) {
    margin-right: 6px;
  }
}

.info-button {
  margin-left: 11px;

  @media (max-width: 380px) {
    margin-left: 6px;
  }
}

.music-button {
  margin-left: 8px;
  margin-right: 8px;

  @media (max-width: 380px) {
    margin-left: 4px;
    margin-right: 4px;
  }
}